<template>
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
        active-text-color="#ff9200">

        <el-submenu index="/article" id="child">
            <template slot="title">Widget</template>
            <el-menu-item index="/works">Plugin</el-menu-item>
            <el-menu-item index="/clients">Clients</el-menu-item>
            <el-menu-item index="/codes">Codes search</el-menu-item>
            <el-menu-item index="/requestDemo">Request a Demo</el-menu-item>
            <el-menu-item index="/join">Join Contrimetric Family</el-menu-item>
            <el-menu-item index="/newcase">New Case</el-menu-item>
        </el-submenu>

        <el-menu-item index="/ai">AI</el-menu-item>
        <!-- <el-menu-item index="/database">Database</el-menu-item> -->

        <el-submenu index="/about" id="child" :class="aboutClass">
            <template slot="title">
                <span style="color: #fff;" class="about_text" @click="goToAbout">About</span>
            </template>
            <el-menu-item index="/researchers">Researchers</el-menu-item>
            <el-menu-item index="/Contrimetrics">Contrimetrics</el-menu-item>
            <el-menu-item index="/Publications">Publications</el-menu-item>
            <!-- <el-menu-item index="/Team">Team</el-menu-item>
            <el-menu-item index="/Careers">Careers</el-menu-item> -->
            <el-menu-item index="/Contact">Contact</el-menu-item>
        </el-submenu>

        <el-menu-item index="/help">Help & Support</el-menu-item>

        <el-button type="primary" class="apply_btn" @click="goToRequestDemo">Request a
            Demo</el-button>
    </el-menu>
</template>

<script>
export default {
    data() {
        return {
            activeIndex: "/index",
        }
    },
    watch: {
        // 监听路由变化
        "$route.path": {
            handler(routePath) {
                if (routePath == "/about") {
                    this.aboutClass = 'float-hand'
                } else {
                    this.aboutClass = ''
                }

                this.initMenuActive(routePath);
            },
            immediate: true,
        },
    },
    methods: {
        initMenuActive(routePath) {
            this.activeIndex = routePath;
            if (routePath == '/') {
                this.activeIndex = '/index'
            }
        },
        handleSelect(index) {
            const validRoutes = ["/database", "/codes", "/Publications", "/Team", "/Careers", "/Contact"];

            if (validRoutes.includes(index)) return
            this.$router.push(index);
        },
        goToAbout() {
            this.$router.push('/about');
        },
        goToRequestDemo() {
            this.$router.push('/requestDemo');
        }
    }
}
</script>

<style lang='scss'>
.el-menu-demo {
    display: flex;
    align-items: center;

    li,
    .el-submenu__title {
        font-size: 18px;
    }

    .float-hand {
        .el-submenu__title {
            border-bottom-color: rgb(255, 146, 0) !important;

            .about_text {
                color: rgb(255, 146, 0) !important;
            }
        }
    }

    .apply_btn {
        margin-left: 20px;
    }
}

#warp-menu>.el-menu {
	background-color: transparent !important;
}

#warp-menu>.el-menu.el-menu--horizontal {
	border: none;
}

#warp-menu>.el-menu--horizontal>.el-menu-item {
	color: #fff;
}

#warp-menu>.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
	color: #fff;
}

#warp-menu>.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
#warp-menu>.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover {
	background-color: transparent !important;
}

//子导航栏
//#warp-menu>.el-menu>.el-submenu为子集的li模块
#warp-menu>.el-menu>.el-submenu>.el-submenu__title {
	color: #fff;

}

#warp-menu>.el-menu>.el-submenu>.el-submenu__title:hover {
	background: transparent !important;
}

//二级菜单导航箭头颜色
#warp-menu>.el-menu>.el-submenu>.el-submenu__title i {
	color: #fff;
}
</style>