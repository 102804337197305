<template>
    <div class="around_list">
        <div class="around_item">
            <div class="item_text_box">
                <div class="item_title">Real-time updates: Instant notifications of latest citations</div>
                <div class="item_desc">The AI-Enhanced Plugin provides <span class="storng_text">live
                        notifications</span> whenever a new citation is
                    registered, ensuring researchers are immediately aware of their work’s impact. By leveraging
                    real-time data processing, users can:</div>
                <div class="item_parts">
                    <div class="item_part1">
                        <i class="el-icon-check"></i>
                        <div><span class="storng_text">Track influence as it happens:</span> Understand how their work
                            is being
                            referenced, where it’s being
                            cited, and in what context. This immediate feedback allows researchers to see the evolving
                            narrative around their findings.</div>
                    </div>
                    <div class="item_part2">
                        <i class="el-icon-check"></i>
                        <div>
                            <span class="storng_text"></span> With quick access to new citations, researchers can
                            respond to
                            discussions,
                            connect with p eers citing their work, or even collaborate on follow-up research projects.
                        </div>
                    </div>
                </div>
            </div>
            <div class="around_iamge">
                <img src="../assets/images/home/home_1.png" alt="">
            </div>
        </div>

        <div class="around_item around_reverse">
            <div class="around_iamge">
                <img src="../assets/images/home/home_2.png" alt="">
            </div>
            <div class="item_text_box">
                <div class="item_title">Boost impact factor: Accelerate research influence</div>
                <div class="item_desc">This feature empowers researchers to <span class="storng_text">boost their
                        journal’s impact factor</span> by
                    providing them with timely access to cutting-edge research in their field. Researchers can:</div>
                <div class="item_parts">
                    <div class="item_part1">
                        <i class="el-icon-check"></i>
                        <div><span class="storng_text">Emerging trends:</span> By staying updated with recent citations
                            and publications, researchers can
                            tailor their research topics to align with the latest trends, enhancing the relevance and
                            reach of their work.</div>
                    </div>
                    <div class="item_part2">
                        <i class="el-icon-check"></i>
                        <div>
                            <span class="storng_text">Strategically enhance citations:</span> By quickly accessing
                            similar or complementary studies,
                            researchers can cite and build upon recent findings, improving the visibility of their own
                            work and fostering collaborative citation practices.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="around_item">
            <div class="item_text_box">
                <div class="item_title">Visual insights: Intuitive, data-rich visualizations</div>
                <div class="item_desc">Understanding citation metrics has never been easier with the plugin’s <span
                        class="storng_text">data
                        visualization capabilities.</span> Through intuitive graphics and interactive dashboards, users
                    can:</div>
                <div class="item_parts">
                    <div class="item_part1">
                        <i class="el-icon-check"></i>
                        <div><span class="storng_text">Analyze citation patterns:</span> View and interpret citation
                            trends over time, breaking down data
                            by year, type of publication, or geographic region to understand where their research
                            resonates most.</div>
                    </div>
                    <div class="item_part2">
                        <i class="el-icon-check"></i>
                        <div>
                            <span class="storng_text">Identify key influencers and networks:</span> By mapping out
                            citation networks, researchers can
                            identify influential authors, institutions, or journals that frequently engage with their
                            work, offering strategic insights for future collaborations.
                        </div>
                    </div>
                </div>
            </div>
            <div class="around_iamge">
                <img src="../assets/images/home/home_3.png" alt="">
            </div>
        </div>

        <div class="around_item around_reverse">
            <div class="around_iamge">
                <img src="../assets/images/home/home_4.png" alt="">
            </div>
            <div class="item_text_box">
                <div class="item_title">Easy integration: Seamless, user-centric installation</div>
                <div class="item_desc">The AI-Enhanced Plugin is engineered for <span class="storng_text">hassle-free
                        integration</span> into existing
                    research workflows, providing users with a straightforward setup process and an intuitive interface.
                    This user-centric design ensures:</div>
                <div class="item_parts">
                    <div class="item_part1">
                        <i class="el-icon-check"></i>
                        <div><span class="storng_text">Quick setup and compatibility:</span> The plugin is compatible
                            with various research management
                            systems and citation tools, allowing researchers to integrate it into their daily routines
                            with minimal disruption.</div>
                    </div>
                    <div class="item_part2">
                        <i class="el-icon-check"></i>
                        <div>
                            <span class="storng_text">Enhanced productivity:</span> With its easy-to-use dashboard,
                            researchers can access all relevant
                            metrics and insights in one place, eliminating the need for complex navigation and saving
                            valuable time.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="around_item">
            <div class="item_text_box">
                <div class="item_title">Advanced AI-powered features: Unleashing the scholarly potential</div>
                <div class="item_desc">Driven by sophisticated AI algorithms, the plugin not only tracks and displays
                    data but also provides:</div>
                <div class="item_parts">
                    <div class="item_part1">
                        <i class="el-icon-check"></i>
                        <div><span class="storng_text">Predictive analytics:</span> Gain forecasts on future citation
                            potential based on past trends,
                            helping researchers plan their outreach or collaborations effectively.</div>
                    </div>
                    <div class="item_part2">
                        <i class="el-icon-check"></i>
                        <div>
                            <span class="storng_text">Personalized recommendations:</span> Discover recommended
                            journals, conferences, and researchers to
                            collaborate with based on citation behavior and research focus.
                        </div>
                    </div>
                </div>
            </div>
            <div class="around_iamge">
                <img src="../assets/images/home/home_5.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
@import "../assets/css/minix.scss";

.around_list {
    @include responsive-width(1200px);

    .storng_text {
        font-weight: bold;
    }

    .around_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 1.5;
        margin: 8em 0;

        .item_text_box {
            flex: 0 0 48%;

            .item_title,
            .item_desc,
            .item_parts {
                font-size: 16px;
            }

            .item_title {
                font-size: 30px;
                font-weight: bold;
                line-height: 1.36;
                color: black;
            }

            .item_desc {
                margin: 20px 0;
            }

            .item_parts {

                .item_part1,
                .item_part2 {
                    display: flex;
                    align-items: baseline;
                    margin-bottom: 10px;

                    div {
                        margin-left: 14px;
                    }
                }

                .item_part1 {
                    margin-bottom: 10px;
                }

                div {
                    position: relative;

                    i {
                        background-color: #e1f8fa;
                        padding: 4px;
                        border-radius: 50%;
                        color: #1da6b8;
                    }
                }
            }
        }

        .around_iamge {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }
}

@media (max-width: 768px) {
    .around_list {
        .around_item {
            flex-direction: column;
            margin: 4em 0;

            .item_text_box {
                .item_title {
                    font-size: 24px;
                }
            }

            .around_iamge {
                text-align: center;

                img {
                    width: 70%;
                }
            }
        }

        .around_reverse {
            flex-direction: column-reverse;
        }
    }
}
</style>