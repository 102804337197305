<template>
    <div>
        <div class="evaluate_box" v-if="isShowEvaluate">
            <EvaluateListVue :isShowEvaluateTitle="isShowEvaluateTitle"></EvaluateListVue>
        </div>

        <div class="faq_box" v-if="isShowFaq">
            <FaqVue></FaqVue>
        </div>
    </div>
</template>

<script>
import EvaluateListVue from "./evaluateList.vue"
import FaqVue from "./faq.vue"

export default {
    components: {
        EvaluateListVue,
        FaqVue
    },
    props: {
        isShowEvaluate: {
            type: Boolean,
            default: true
        },
        isShowEvaluateTitle: {
            type: Boolean,
            default: true
        },
        isShowFaq: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss">
.faq_box {
    margin-top: 80px;
    background: url(../../assets/images/faq_bj.png);
}

.evaluate_box,
.faq_box {
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    .faq_box {
        margin-top: 30px;

        .faq_container {
            padding: 20px;

            .faq_content {
                .faq_content_list {
                    .collapse {
                        flex-direction: column;

                        .collapse_item {
                            flex: 1;
                            padding: 3px 0;

                            .el-collapse-item__header {
                                line-height: 1.3;
                                padding: 10px 0;
                                width: 100%;
                            }

                            .el-collapse-item__wrap{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .faq_box {
        .faq_container {
            .faq_content {
                .faq_content_list {
                    .collapse {
                        .collapse_item {
                            .el-collapse-item__header {
                                line-height: 1.3;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>