<template>
    <div class="scroll_bar_box"
        :style="{ width: total <= pageSize ? 0 : width + 'px', opacity: visibility == 'visible' ? 1 : 0 }">
        <div class="scroll_bar"
            :style="{ height: getScrollHeight() + '%', transform: `translateY(${getScrollTop()}px)` }"></div>
    </div>
</template>

<script>
export default {
    props: {
        total: {
            type: Number,
            default: 0
        },
        current: {
            type: Number,
            default: 0
        },
        pageSize: {
            type: Number,
            default: 10
        },
        height: {
            type: Number,
            default: 600
        },
        width: {
            type: Number,
            default: 10
        },
        visibility: {
            type: String,
            default: 'visible'
        }
    },
    data() {
        return {

        }
    },
    methods: {
        getScrollHeight() {
            return (this.pageSize / (this.total - 1)) * 100
        },
        getScrollTop() {
            const leaveHeight = this.height - this.getScrollHeight() / 100 * this.height

            return ((this.current / (this.total - 1))) * leaveHeight
        }
    }
}
</script>

<style lang='scss'>
.scroll_bar_box {
    transition: all .3s;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;


    .scroll_bar {
        background-color: #c7c7c7;
        border-radius: 10px;
    }
}
</style>