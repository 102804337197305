import { obfuscateObject, objectToQueryString } from "@/utils/utils"
import chinaImage from "@/assets/images/china_consultant.png"
import malaysiaImage from "@/assets/images/malaysia_consultant.png"
import aminImage from "@/assets/images/team/Amin.png"
import yizhengrongImage from "@/assets/images/Yizhengrong.jpg"

export const pageSearchTitle = "Check if your journal codes generated?"
export const pageSearchPlaceholder = "Search ISSN or Journal name"

export const NODATA_PAGE_NAME = 'none'
export const DATA_PAGE_NAME = 'joint'

export const noDataSteps = [
    {
        title: "Find your Journal",
        description: "First, find the journal where you need to install the plugin",
        video: '',
    },
    {
        title: "Request Code and Application",
        description: "Apply for your journal plugin code, copy and paste the code onto the designated page",
        video: '',
    },
    {
        title: "Code Installation Completed",
        description: "Check the installation effect of plugins",
        video: '',
    }
]

export const dataTable = [
    {
        journalName: "West Kazakhstan Medical Journal",
        issn: "ISSN: 2707-6180 (Print) 2707-6199 (Online)",
    },
    {
        journalName: "West Kazakhstan Medical Journal",
        issn: "ISSN: 2707-6180 (Print) 2707-6199 (Online)",
    },
    {
        journalName: "West Kazakhstan Medical Journal",
        issn: "ISSN: 2707-6180 (Print) 2707-6199 (Online)",
    },
    {
        journalName: "West Kazakhstan Medical Journal",
        issn: "ISSN: 2707-6180 (Print) 2707-6199 (Online)",
    }
]

export const MC = ['Southeast Asia', 'China', 'Middle East', 'Central Asia']

export const onsultantObj = {
    'Southeast Asia': {
        name: "PROF. Muhammad Aqeel Ashraf",
        rank: "Senior Consultant",
        rightTitle: "Southeast Asia-Speaking Sales",
        rightSupport: "Southeast Asia Speaking Sales Support:",
        email: "contact@contrimetric.com",
        image: malaysiaImage
    },
    'China': {
        name: "Kristine GUO",
        rank: "Senior Consultant",
        rightTitle: "China-Speaking Sales",
        rightSupport: "China Speaking Sales Support:",
        email: "kristineguo@contrimetric.com",
        image: chinaImage
    },
    'Middle East': {
        name: "Dr.Amin D. Malayeri",
        rank: "Senior Consultant",
        rightTitle: "Middle East-Speaking Sales",
        rightSupport: "Middle East Speaking Sales Support:",
        email: "amin.daneshmand@contrimetric.com",
        image: aminImage
    },
    'Central Asia': {
        name: "Ilyosbek Boltaboev",
        rank: "Senior Consultant",
        rightTitle: "Central Asia-Speaking Sales",
        rightSupport: "Central Asia Speaking Sales Support:",
        email: "ilyosbekboltaboev19@icloud.com",
        image: yizhengrongImage
    }
}

export const countriesList = [
    "China", "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda",
    "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain",
    "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia",
    "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso",
    "Burundi", "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic",
    "Chad", "Chile", "Colombia", "Comoros", "Congo, Democratic Republic of the",
    "Congo, Republic of the", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic",
    "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador",
    "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland",
    "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada",
    "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary",
    "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy",
    "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea, North",
    "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon",
    "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar",
    "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania",
    "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro",
    "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands",
    "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", "Oman",
    "Pakistan", "Palau", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru",
    "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis",
    "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe",
    "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia",
    "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Sudan", "Spain", "Sri Lanka",
    "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania",
    "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey",
    "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
    "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
    "Yemen", "Zambia", "Zimbabwe", "Other"
];

export const SEARCHLETTER = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

export const dealRouterPath = (path, total, router, params) => {
    const jsonString = objectToQueryString(obfuscateObject(params))

    if (path === `/${NODATA_PAGE_NAME}` && total === 0) {
        return;
    }

    if (path === `/${NODATA_PAGE_NAME}` && total > 0) {
        router.push(`/${DATA_PAGE_NAME}?${jsonString}`);
    }

    if (path === `/${DATA_PAGE_NAME}` && total === 0) {
        router.push(`/${NODATA_PAGE_NAME}`);
    }


    if (path === `/${DATA_PAGE_NAME}` && total > 0) {
        router.push(`/${DATA_PAGE_NAME}?${jsonString}`);
    }

    if (path !== `/${NODATA_PAGE_NAME}` && path !== `/${DATA_PAGE_NAME}` && total === 0) {
        router.push(`/${NODATA_PAGE_NAME}`);
    }

    if (path !== `/${NODATA_PAGE_NAME}` && path !== `/${DATA_PAGE_NAME}` && total > 0) {
        router.push(`/${DATA_PAGE_NAME}?${jsonString}`);
    }
}

export const wosOfPubList = [
    {
        link: "https://www.discovmed.com/EN/home",
        pubImg: require("@/assets/images/publication/pub_1.png"),
        wosImg: require("@/assets/images/publication/pub_of.png"),
        pubPlusImg: require("@/assets/images/publication/pub_plus_1.png"),
    },
    {
        link: "https://kneopen.com/journal/dmj/",
        pubImg: require("@/assets/images/publication/pub_2.png"),
        wosImg: require("@/assets/images/publication/pub_of.png"),
        pubPlusImg: require("@/assets/images/publication/pub_plus_2-4.png"),
    },
    {
        link: "https://kneopen.com/journal/ijrm/",
        pubImg: require("@/assets/images/publication/pub_3.png"),
        wosImg: require("@/assets/images/publication/pub_of.png"),
        pubPlusImg: require("@/assets/images/publication/pub_plus_2-4.png"),
    },
    {
        link: "https://kneopen.com/journal/jovr/",
        pubImg: require("@/assets/images/publication/pub_4.png"),
        wosImg: require("@/assets/images/publication/pub_of.png"),
        pubPlusImg: require("@/assets/images/publication/pub_plus_2-4.png"),
    },
]