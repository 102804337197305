<template>
    <div class="is_plugin">
        <div class="is_plugin_title">{{ title }}</div>
        <div class="is_plugin_input">
            <el-input :placeholder="placeholder" clearable v-model="getJourSearchParams.commonCondition" class="custom_placeholder"
                @keyup.enter.native="onCheckPlugin">
                <el-button slot="append" class="is_plugin_append" @click="onCheckPlugin">
                    <!-- <img src="../../assets/images/magnifying_glass.png" alt=""> -->
                    <span>{{ btnText }}</span>
                </el-button>
            </el-input>
        </div>
        <div >
            
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { NODATA_PAGE_NAME, DATA_PAGE_NAME } from "@/views/widget/data"

export default {
    props: {
        title: {
            type: String,
            default: '',
            required: true
        },
        btnText: {
            type: String,
            default: 'Search'
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters(['getJourSearchParams']),
    },
    data() {
        return {
            NODATA_PAGE_NAME,
            DATA_PAGE_NAME,
        }
    },
    methods: {
        onCheckPlugin() {
            this.$emit('update:value', this.getJourSearchParams.commonCondition)
        }
    }
}
</script>

<style lang="scss">
.is_plugin .is_plugin_title {
    font-family: MicrosoftTaiLe-Bold;
    font-size: 30px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 20px;
}

.custom_placeholder .el-input__inner::placeholder {
    color: #999 !important;
}

.is_plugin .is_plugin_input .is_plugin_append {
    height: 40px;
    background-color: #1da6b8;
    color: white;
    border-radius: 0;
}

.is_plugin .is_plugin_input .is_plugin_append img {
    width: 28px;
    height: 28px;
    margin-right: 6px;
}

@media (max-width: 768px) {
	.is_plugin .is_plugin_title{
        font-size: 24px;
    }
}
</style>