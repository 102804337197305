import {
	createStore
} from 'vuex'
import { getJournalList } from "@/api/widget";
import { Loading } from 'element-ui';

export default createStore({

	state() {
		return {
			userInfo: [],
			tokenInfo: [],
			isLogin: false,
			expireTime: '',

			journalData: {},
			jourSearchParams: {
				page: 1,
				pageSize: 10,
				commonCondition: "",
				journalName: ""
			}
		};
	},

	mutations: {
		setUserInfo(state, userInfo) {
			localStorage.setItem('userInfo', JSON.stringify(userInfo))
			state.userInfo = userInfo
		},
		setTokenInfo(state, tokenInfo) {
			localStorage.setItem('tokenInfo', JSON.stringify(tokenInfo))
			state.tokenInfo = tokenInfo

		},
		setIsLogin(state, isLogin) {
			localStorage.setItem('isLogin', isLogin)
			state.isLogin = isLogin
		},
		setExpireTime(state, expireTime) {
			const currentTime = Date.now()
			expireTime = currentTime + (expireTime - 60) * 1000
			localStorage.setItem('expireTime', JSON.stringify(expireTime))
			state.expireTime = expireTime
		},

		setJournalData(state, data) {
			data.records.map(item => item.normal = [])
			state.journalData = data;
		},
		setJourSearchParams(state, params) {
			state.jourSearchParams = {
				...state.jourSearchParams,
				...params
			};
		}
	},

	getters: {
		getUserInfo: state => {
			var localUserInfo = localStorage.getItem("userInfo")

			state.userInfo = localUserInfo == undefined || localUserInfo == "" || localUserInfo == 'undefined' ? "" : JSON.parse(localStorage
				.getItem("userInfo"))
			return state.userInfo
		},
		getTokenInfo: state => {
			state.tokenInfo = localStorage.getItem("tokenInfo") == undefined || localStorage.getItem(
				"tokenInfo") == "" ? "" : JSON.parse(localStorage
					.getItem("tokenInfo"))

			return state.tokenInfo
		},
		getExpireTime: state => {
			state.expireTime = localStorage.getItem("expireTime") == undefined || localStorage.getItem(
				"expireTime") == "" ? "" : JSON.parse(localStorage
					.getItem("expireTime"))

			return state.expireTime
		},
		getIsLogin: state => {
			state.isLogin = localStorage.getItem("isLogin") == undefined || localStorage.getItem(
				"isLogin") == "" ? false : JSON.parse(localStorage
					.getItem("isLogin"))
			return state.isLogin
		},

		getJournalData: (state) => state.journalData,
		getJourSearchParams: (state) => state.jourSearchParams
	},

	actions: {
		async fetchJournalData({ commit, state }) {
			const params = state.jourSearchParams;
			const loadingInstance = Loading.service();

			await getJournalList(params).then(res => {
				commit('setJournalData', { ...res.data, inputValue: params.commonCondition, journalName: params.journalName });
			}).catch(() => {
				commit('setJournalData', { records: [], total: 0, inputValue: params.commonCondition });
			}).finally(() => {
				setTimeout(() => {
					loadingInstance.close();
				}, 200);
			});
		}
	},
});