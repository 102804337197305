import case1 from "@/assets/images/case1.png"
import case2 from "@/assets/images/case2.png"

export const collapseList = [
    {
        title: 'Discovery Medicine',
        issn: '1539-6509; EISSN: 1944-7930',
        describe: "",
        image: case2,
        url: "https://www.discovmed.com/EN/archive_by_years",
        periodicalList: []
    },
    {
        title: 'KnE Open',
        issn: '2518-668X',
        describe: "We have worked with over 25,000 researchers from over 60 countries, and KnE Open hosts publications from over 30 countries, and has over 2mn visitors a year, from 193 countries across the world. ",
        image: case1,
        url: "https://kneopen.com/",
        periodicalList: [
            {
                name: "Arab Journal of Nutrition and Exercise",
                url: "https://kneopen.com/journal/ajne/"
            },
            {
                name: "Dubai Diabetes and Endocrinology Journal",
                url: "https://kneopen.com/journal/ddej"
            },
            {
                name: "Dubai Medical Journal",
                url: "https://kneopen.com/journal/dmj/"
            },
            {
                name: "ESPOCH Congresses: The Ecuadorian Journal of S.T.E.A.M.",
                url: "https://kneopen.com/journal/espoch"
            },
            {
                name: "Gulf Education and Social Policy Review",
                url: "https://kneopen.com/journal/gespr/"
            },
            {
                name: "International Journal of Reproductive BioMedicine",
                url: "https://kneopen.com/journal/ijrm/"
            },
            {
                name: "Journal of Environmental Treatment Techniques",
                url: "https://kneopen.com/journal/jett"
            },
            {
                name: "Journal of Infertility and Reproductive Biology",
                url: "https://kneopen.com/journal/journal-of-infertility-and-reproductive-biology"
            },
            {
                name: "Journal of Ophthalmic and Vision Research",
                url: "https://kneopen.com/journal/jovr"
            },
            {
                name: "Journal of Spine Practice",
                url: "https://kneopen.com/journal/jsp/"
            },
            {
                name: "KnE Energy",
                url: "https://kneopen.com/journal/kne-energy"
            },
            {
                name: "KnE Engineering",
                url: "https://kneopen.com/journal/kne-engineering"
            },
            {
                name: "KnE Life Sciences",
                url: "https://kneopen.com/journal/kne-life/"
            },
            {
                name: "KnE Materials Science",
                url: "https://kneopen.com/journal/kne-materials"
            },
            {
                name: "KnE Medicine",
                url: "https://kneopen.com/journal/kne-medicine"
            },
            {
                name: "KnE Social Sciences",
                url: "https://kneopen.com/journal/kne-social"
            },
            {
                name: "Sudan Journal of Medical Sciences",
                url: "https://kneopen.com/journal/sjms/"
            },
            {
                name: "West Kazakhstan Medical Journal",
                url: "https://kneopen.com/journal/west-kazakhstan-medical-journal"
            },
        ]
    }
]