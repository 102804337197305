<template>
    <div class="evaluate_container">
        <div class="evaluate_title" v-if="isShowEvaluateTitle">
            WHAT DO OUR CUSTOMERS THINK OF THE PLUGIN?
        </div>
        <div class="evaluate_list">
            <el-carousel :height="is1200 ? '264px' : '142px'" indicator-position="none" arrow="never" ref="carouselRef">
                <el-carousel-item v-for="(item, index) in evaluateList" :key="index">
                    <div class="evaluate_item">
                        <div class="evaluate_item_img">
                            <img :src="item.authorImage" alt="">
                        </div>
                        <div class="evaluate_item_content">
                            <div class="name">{{ item.name }}</div>
                            <div class="technical">{{ item.technical }}</div>
                            <div class="content"> {{ item.content }}</div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>

            <div class="carousel_dot">
                <div class="dot_item" @click="onCarouselClick('prev')">〈
                </div>
                <div class="dot_item" @click="onCarouselClick('next')">〉</div>
            </div>
        </div>
    </div>
</template>

<script>
import { evaluateList } from "./data"

export default {
    props: {
        isShowEvaluateTitle: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            evaluateList,
            is1200:false
        }
    },
    methods: {
        onCarouselClick(type) {
            if (type === 'prev') {
                this.$refs.carouselRef.prev()
            } else {
                this.$refs.carouselRef.next()
            }
        },
        updatePagerCount() {
            const width = window.innerWidth;
            this.is1200 = width < 768;
        },
    },
    mounted() {
        this.updatePagerCount();
        window.addEventListener('resize', this.updatePagerCount);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updatePagerCount);
    },
}
</script>

<style lang="scss">
@import '../../assets/css/minix.scss';

.evaluate_container {
    @include responsive-width(1200px);

    .evaluate_title {
        font-size: 30px;
        text-align: center;
        font-weight: bold;
        margin-top: 60px;
        margin-bottom: 40px;
    }

    .evaluate_list {
        position: relative;
        border-radius: 12px;
        width: 100%;
        border: 1px dashed #1da6b8;

        &:hover {
            .carousel_dot {
                opacity: 1;
            }
        }

        .carousel_dot {
            position: absolute;
            right: 30px;
            top: 16px;
            display: flex;
            font-weight: bold;
            font-size: 26px;
            transition: all .3s;
            opacity: 0;

            .dot_item {
                z-index: 9;
                color: #979797;
                transition: all .3s;

                &:nth-child(1) {
                    margin-right: 20px;
                }

                &:hover {
                    cursor: pointer;
                    color: #25a9ba;
                }
            }

            .disabled_dot {
                color: #cbcbcb !important;

                &:hover {
                    cursor: not-allowed;
                }
            }
        }

        .evaluate_item {
            display: flex;
            // justify-content: center;
            padding: 30px 50px;

            .evaluate_item_img {
                margin-right: 20px;

                img {
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                }
            }

            .evaluate_item_content {
                display: flex;
                flex-direction: column;
                line-height: 30px;

                .name {
                    color: #1da6b8;
                    font-size: 18px;
                    font-weight: bold;
                }

                .technical {
                    color: #9f9f9f;
                }

                .content {
                    margin-top: 10px;
                    color: #424242;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .technical,
                .content {
                    font-size: 16px;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .el-carousel__container {
            .evaluate_item {
                padding: 30px;
            }
        }

        .evaluate_list .carousel_dot {
            top: auto;
            bottom: 16px;
        }

        .evaluate_title{
            margin-top: 30px;
            font-size: 24px;
        }
    }
}
</style>