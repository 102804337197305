export const props = {}

export const defaultContentTitle = 'AI Enhanced Plugin'
export const defaultContentList = [
    'Real-time Updates: notification with the latest citations',
    'Boost Impact Factor: access timely cutting-edge researches',
    'Visual Insights: intuitive visualizations data',
    'Easy Integration: Seamless plugin for a user-friendly experience'
]

export const validRoutes = ["/database", "/codes", "/Publications", "/Team", "/Careers", "/Contact"];