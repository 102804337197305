import case1 from "@/assets/images/case1.png"
import case2 from "@/assets/images/case2.png"

export const testList = [
    {
        id: 0,
        journalName: "Arab Journal of Nutrition and Exercise",
        imgurl: case1,
        website: "https://kneopen.com/journal/ajne/"
    },
    {
        id: 1,
        journalName: "Dubai Diabetes and Endocrinology Journal",
        imgurl: case2,
        website: "https://kneopen.com/journal/ddej"
    },
    {
        id: 2,
        journalName: "Dubai Medical Journal",
        imgurl: case1,
        website: "https://kneopen.com/journal/dmj/"
    },
    {
        id: 3,
        journalName: "ESPOCH Congresses: The Ecuadorian Journal of S.T.E.A.M.",
        imgurl: case1,
        website: "https://kneopen.com/journal/espoch"
    },
    {
        id: 4,
        journalName: "Gulf Education and Social Policy Review",
        imgurl: case1,
        website: "https://kneopen.com/journal/gespr/"
    },
    {
        id: 5,
        journalName: "International Journal of Reproductive BioMedicine",
        imgurl: case1,
        website: "https://kneopen.com/journal/ijrm/"
    },
    {
        id: 6,
        journalName: "Journal of Environmental Treatment Techniques",
        imgurl: case1,
        website: "https://kneopen.com/journal/jett"
    },
    {
        id: 7,
        journalName: "Arab Journal of Nutrition and Exercise",
        imgurl: case1,
        website: "https://kneopen.com/journal/ajne/"
    },
    {
        id: 8,
        journalName: "Dubai Diabetes and Endocrinology Journal",
        imgurl: case2,
        website: "https://kneopen.com/journal/ddej"
    },
    {
        id: 9,
        journalName: "Dubai Medical Journal",
        imgurl: case1,
        website: "https://kneopen.com/journal/dmj/"
    },
    {
        id: 10,
        journalName: "ESPOCH Congresses: The Ecuadorian Journal of S.T.E.A.M.",
        imgurl: case1,
        website: "https://kneopen.com/journal/espoch"
    },
    {
        id: 11,
        journalName: "Gulf Education and Social Policy Review",
        imgurl: case1,
        website: "https://kneopen.com/journal/gespr/"
    },
    {
        id: 12,
        journalName: "International Journal of Reproductive BioMedicine",
        imgurl: case1,
        website: "https://kneopen.com/journal/ijrm/"
    },
    {
        id: 13,
        journalName: "Journal of Environmental Treatment Techniques",
        imgurl: case1,
        website: "https://kneopen.com/journal/jett"
    },
    {
        id: 14,
        journalName: "Arab Journal of Nutrition and Exercise",
        imgurl: case1,
        website: "https://kneopen.com/journal/ajne/"
    },
    {
        id: 15,
        journalName: "Dubai Diabetes and Endocrinology Journal",
        imgurl: case2,
        website: "https://kneopen.com/journal/ddej"
    },
    {
        id: 16,
        journalName: "Dubai Medical Journal",
        imgurl: case1,
        website: "https://kneopen.com/journal/dmj/"
    },
    {
        id: 17,
        journalName: "ESPOCH Congresses: The Ecuadorian Journal of S.T.E.A.M.",
        imgurl: case1,
        website: "https://kneopen.com/journal/espoch"
    },
    {
        id: 18,
        journalName: "Gulf Education and Social Policy Review",
        imgurl: case1,
        website: "https://kneopen.com/journal/gespr/"
    },
    {
        id: 19,
        journalName: "International Journal of Reproductive BioMedicine",
        imgurl: case1,
        website: "https://kneopen.com/journal/ijrm/"
    },
    {
        id: 20,
        journalName: "Journal of Environmental Treatment Techniques",
        imgurl: case1,
        website: "https://kneopen.com/journal/jett"
    },
    {
        id: 21,
        journalName: "Arab Journal of Nutrition and Exercise",
        imgurl: case1,
        website: "https://kneopen.com/journal/ajne/"
    },
    {
        id: 22,
        journalName: "Dubai Diabetes and Endocrinology Journal",
        imgurl: case2,
        website: "https://kneopen.com/journal/ddej"
    },
    {
        id: 23,
        journalName: "Dubai Medical Journal",
        imgurl: case1,
        website: "https://kneopen.com/journal/dmj/"
    },
    {
        id: 24,
        journalName: "ESPOCH Congresses: The Ecuadorian Journal of S.T.E.A.M.",
        imgurl: case1,
        website: "https://kneopen.com/journal/espoch"
    },
    {
        id: 25,
        journalName: "Gulf Education and Social Policy Review",
        imgurl: case1,
        website: "https://kneopen.com/journal/gespr/"
    }
]