// 混淆函数
export function obfuscateObject(obj) {
    const obfuscationMap = {
        page: 'p',
        pageSize: 'ps',
        commonCondition: 'cc',
        journalName: 'jn',
    };

    const obfuscatedObj = {};

    // 遍历对象，将键名混淆
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            obfuscatedObj[obfuscationMap[key] || key] = obj[key];
        }
    }

    return obfuscatedObj;
}

// 解混淆函数
export function objectToQueryString(obj) {
    return Object.keys(obj)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join('&');
}


// 修改url参数
export function updateUrlParameter(param, value) {
    const url = new URL(window.location.href);
    url.searchParams.set(param, value);
    window.history.pushState({}, '', url);
}
