<template>
    <div class="expandable_list">
        <div :class="{ expanded: isExpanded, collapsed: !isExpanded }" class="list-container">
            <div v-for="(item, index) in items" :key="index" class="item" v-show="isExpanded || index < defaultCount">
                <div class="item_content" @click="goToBlankUrl(item.url)">{{ item.name }}</div>
            </div>
        </div>

        <div class="expand_button" v-if="items.length > defaultCount" @click="toggleExpand">
            {{ isExpanded ? 'pack up' : 'expand more' }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        defaultCount: {
            type: Number,
            default: 5
        }
    },
    data() {
        return {
            isExpanded: false
        };
    },
    methods: {
        toggleExpand() {
            this.isExpanded = !this.isExpanded;
        },
        goToBlankUrl(url) {
            window.open(url, '_blank');
        }
    }
};
</script>

<style lang="scss">
.expandable_list {
    .list-container {
        overflow: hidden;
        transition: max-height 0.5s ease;
        max-height: 150px;
    }

    .list-container.expanded {
        max-height: 1000px;
    }

    .list-container.collapsed {
        max-height: 150px;
    }

    .item {
        margin: 5px 0;

        .item_content {
            padding: 2px 0;
            transition: all .3s;

            &:hover {
                cursor: pointer;
                color: #e7e7e7;
                filter: drop-shadow(2px 4px 6px #255261);
            }
        }
    }

    .expand_button {
        margin-top: 10px;
        color: white;
        cursor: pointer;
        border: none;
        font-size: 18px;
        padding: 5px 10px;
        padding-left: 0;
        border-radius: 5px;
    }
}
</style>
