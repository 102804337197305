export const evaluateList = [
    {
        name: 'Hossein Moayedi',
        technical: 'Duy Tân University, Engineering Computer Science Chemistry Environmental Sciences & Ecology Materials Science.',
        content: "",
        authorImage: require('@/assets/images/team1.png'),
    },
    {
        name: 'AL DIWANIYAH, IRAQ',
        technical: 'University of Al-QadisiyahSch Educ,Science & Technology - Other Topics.',
        content: "",
        authorImage: require('@/assets/images/team2.png'),
    },
    {
        name: 'Dr. Abdulrahman S Bazaid',
        technical: 'Department of Medical Laboratory Science, College of Applied Medical Sciences, University of Ha’il, Ha’il.',
        content: "",
        authorImage: require('@/assets/images/team3.png'),
    }
]

export const collapseList = [
    {
        title: 'Is Contrimetric·Plugin free?',
        describe: "Plugin is a completely free extension. It doesn’t require a paid license and doesn’t include any paid features.",
    },
    {
        title: 'How to get started quickly with Contrimetric·Plugin?',
        describe: "Contrimetric’s <a href='https://www.contrimetric.com/newcase'>Quick start guide</a> will tell you how to create and run your first project and teach you about the product’s core capabilities. For those who are new to plugin, there is a set of interactive courses that will help you get to grips with the essential functionalities quickly.",
    },
    {
        title: 'How to report feedback/request a feature?',
        describe: "If you have any ideas on how to improve plugin, or if you’d like to request a specific feature, please leave your contact information,and someone will reach out to you shortly.",
    },
    {
        title: 'Will Plugin be open source?',
        describe: "We are considering open sourcing parts of the product or the technology behind it. Stay tuned for details in the coming months.",
    }
]