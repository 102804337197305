<template>
	<div>
		<section class="header-bg">
			<!-- <div class="container-1200" style="padding-top: 40px;"> -->
			<div class="container-1200" style="">
			</div>

		</section>
		<!-- ai enhance plugin -->
		<div class="box">
			<div class="box1">
				<div class="box1_left">
					<div>AI-enhanced plugin by Contrimetric: Redefining research with intelligent automation</div>
					<div class="box1_left_text_desc">Contrimetric is designed to streamline the way researchers and
						academics interact with their citation metrics, enabling them to stay updated, strategically
						boost their research influence, and visualize their impact in real time:</div>

					<div class="box1_right_search">
						<PageSearch :title="pageSearchTitle" :placeholder="pageSearchPlaceholder"
							@update:value="onCheckPlugin"></PageSearch>
					</div>
				</div>
				<div class="box1_right">
					<img :src="require('@/assets/images/aboutR.png')" alt="">
				</div>
			</div>
		</div>

		<div class="new_cases_box">
			<HomeAroundVue></HomeAroundVue>
		</div>

		<div class="new_cases_box">
			<pubCarousel></pubCarousel>
		</div>

		<div class="box_1">
			<div class="box_11">
				<img :src="require('@/assets/images/index_widget.png')" alt="">
			</div>
		</div>

		<div v-if="false">
			<EvaluateIssue :isShowFaq="false"></EvaluateIssue>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { dealRouterPath } from "./widget/data"
import $http from "@/request/http";
import PageSearch from "@/components/pageSearch/index.vue"
import EvaluateIssue from "@/components/evaluateIssue/index.vue"
import NewCases from "@/components/newCases/index.vue"
import HomeAroundVue from "./homeAround.vue"
import pubCarousel from "@/components/pubCarousel/index.vue"

export default {
	components: {
		PageSearch,
		NewCases,
		EvaluateIssue,
		HomeAroundVue,
		pubCarousel
	},
	data() {
		return {
			articleTitle: "",
			firstName: '',
			lastName: '',
			workEmail: '',
			pageSearchTitle: "Check if your journal codes generated?",
			pageSearchPlaceholder: "Search ISSN or Journal name",
			isAllowSend: false, //是否发送关于Contrimetric的产品				
		};

	},
	computed: {
		...mapGetters(['getJournalData', 'getJourSearchParams']),
	},
	methods: {
		...mapActions(['fetchJournalData']),
		articleDetails() {
			this.$router.push({
				name: "journalSource",
				query: {
					"articleTitle": this.articleTitle
				}
			});
		},
		tipWorks() {
			this.$router.push('/works')
		},
		submit() {
			let paramap = {
				userName: this.firstName + this.lastName,
				userEmail: this.workEmail,
				isAllowSend: this.isAllowSend,
			}

			if (paramap.userName == '' || paramap.userEmail == '') {
				return this.$message.warning("Please enter name and email")
			}

			const loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			$http.postFhl("/journal/inserJournalForm", paramap, true).then(res => {
				if (res.code == 200) {
					this.$message.success("Submit successfully")
					loading.close()
				}
			}).catch(err => {
				console.log(err);
			});
		},
		async onCheckPlugin(inputValue) {
			// 重置条件
			const params = { page: 1, pageSize: this.getJourSearchParams.pageSize, commonCondition: inputValue, journalName: '' }
			delete this.getJourSearchParams.desc;
			delete this.getJourSearchParams.asc;

			this.$store.commit('setJourSearchParams', params);

			const router = this.$router
			const route = this.$route

			await this.fetchJournalData(this.params);

			dealRouterPath(route.path, this.getJournalData.total, router, params)
		}
	},
};
</script>
<style scoped lang="scss">
@import "../assets/css/minix.scss";

.index_search_btn .icon_img {
	width: 28px;
	height: 28px;
	margin-right: 6px;
}

.fluid_h1 {
	font-weight: 700;
	color: white;
	font-size: 38px;
	transform: translateY(20px);
	font-family: 'dingdingjinbu', sans-serif;
}

.header_bottom {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.first,
.second,
.third {
	/* flex:1; */
	width: 30%;
	margin: 33px 0px;
	color: #fff;
	font-size: 48px;
	transform: translateY(14px);
}

.first0 {
	border-bottom: 1px solid #fff;
	font-size: 16px !important;
}

.box {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.box1 {
	@include responsive-width(1200px);
	margin: 40px 0px 30px 0px;
	display: flex;
	justify-content: space-between;
}

.box1_left {
	max-width: 724px;
	line-height: 1.5;
}

.box1_left_text_desc {
	font-size: 16px;
	margin-top: 20px;
}

.box1_left>div:nth-child(1) {
	font-family: MicrosoftTaiLe-Bold;
	font-size: 30px;
	font-weight: bold;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #000000;
	padding-top: 30px;
}

.box1_left>ul {
	margin: 30px 0px 0px 10px;
}

.box1_left>ul {
	list-style-type: none;
	/* 取消默认的列表项样式 */
}

.box1_left>ul li {
	position: relative;
	/* 设置列表项的定位为相对定位 */
	padding-left: 20px;
	/* 增加左边距，以留出位置放置实心圆 */
	font-family: ArialMT;
	font-size: 16px;
	font-weight: 500;
	font-stretch: normal;
	line-height: 26px;
	letter-spacing: 0px;
	color: #000000;
}

.box1_left>ul li::before {
	content: "•";
	/* 自定义实心圆标记 */
	font-size: 30px;
	color: #1da6b8;
	/* 设置实心圆的颜色为红色 */
	position: absolute;
	/* 将实心圆绝对定位 */
	left: 0;
	/* 将实心圆放置在列表项的最左边 */
	top: 44%;
	/* 将实心圆的顶部与列表项文本的中部对齐 */
	transform: translateY(-50%);
	/* 使用 translateY 将实心圆向上移动自身一半的高度，以使其与文本垂直居中 */
}

.box1_left .box1_right_search {
	margin-top: 50px;
}

.new_cases_box {
	display: flex;
	justify-content: center;
	width: 100%;
}

.box_1 {
	width: 100%;
	margin: 40px 0px 60px 0px;
	display: flex;
	justify-content: center;
	align-items: center;

}

.box_11 {
	@include responsive-width(1200px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.box_11>img {
	width: 100%;
}

.box_2 {
	width: 100%;
	/* height: 500px; */
	background-image: url(../assets/images/bg2.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
}

.box_21 {
	width: 40%;
	padding: 120px 0px 60px 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;

}

.box_21>div:nth-child(1) {
	font-family: MicrosoftTaiLe-Bold;
	font-size: 30px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 26px;
	color: #000000;
	text-align: center;
}


.box_21>div:nth-child(2) {
	margin: 30px 0px;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 19px;
	letter-spacing: 0px;
	color: #001111;
}

::v-deep .el-input__inner::placeholder {
	color: #000000;
}

::v-deep .el-input__inner::placeholder::after {
	content: '*',
}

.elInput {
	width: 48%;
}

::v-deep .box_21 .el-input__inner {
	background-color: transparent;
	border: 1px solid black;
}

::v-deep .box_21 .el-checkbox__inner {
	background-color: transparent;
	border: 1px solid black;

}

::v-deep .box_21 .el-checkbox {
	color: #000000;
}

.box_21 .el-button {
	margin-top: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20%;
	background-color: #1da6b8;
	border-radius: 5px;
	border: none;
	color: #fff;
	font-size: 20px;
	padding: 19px 60px 18px 60px;
}

@media (max-width: 768px) {
	.box1_right {
		display: none;
	}

	.box1_left>div:nth-child(1) {
		font-size: 24px;
		padding-top: 10px;
	}
}
</style>