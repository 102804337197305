<template>
    <div class="faq_container">
        <div class="faq_title">FAQ</div>
        <div class="faq_content">
            <div class="faq_content_list">
                <el-collapse v-model="activeName" :accordion="true" @change="onCollapseChange" class="collapse">
                    <el-collapse-item v-for="(item, index) in collapseList" :key="index" :title="item.title"
                        :name="index" :class="index == activeName ? 'faq_active collapse_item' : 'collapse_item'">
                        <template slot="title">
                            <i class="el-icon-close title_icon" v-if="activeName == index"></i>
                            <i class="el-icon-plus title_icon" v-else></i>
                            {{ item.title }}
                        </template>
                        <span v-html="item.describe" class="describe"></span>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
    </div>
</template>

<script>
import { collapseList } from './data'

export default {
    data() {
        return {
            activeName: 0,
            collapseList
        }
    },
    methods: {
        onCollapseChange(name) {
            this.activeName = typeof name == 'string' ? -1 : name
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/minix.scss";

.faq_container {
    @include responsive-width(1200px);
    padding: 50px 0;

    .faq_active {
        .el-collapse-item__header {
            color: #1da6b8;
            border-bottom: none !important;
        }

        i {
            color: #1da6b8 !important;
        }
    }

    .faq_title {
        font-size: 30px;
        font-weight: bold;
        color: black;
        margin-bottom: 20px;
    }

    .faq_content {
        .faq_content_list {
            .collapse {
                border: 0;
                display: flex;
                flex-wrap: wrap;
                width: 100%;

                .collapse_item {
                    flex: 0 0 50%;

                    .el-collapse-item__header,
                    .el-collapse-item__wrap {
                        width: 90%;
                        border-bottom: 1px solid #34393c;
                        background-color: transparent;
                    }

                    .el-collapse-item__header {
                        font-size: 16px;
                        font-weight: bold;
                    }

                    .el-collapse-item__wrap {
                        .el-collapse-item__content {
                            margin-left: 36px;
                        }
                    }

                    .title_icon {
                        margin: 0 10px;
                        font-weight: bold;
                        color: black;
                    }

                    i {
                        &:last-child {
                            display: none;
                        }
                    }

                    .describe {
                        a {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}
</style>